export default [
  {
    path: '/exam',
    name: 'exam',
    meta: {
      title: '考试',
    },
    component: () => import('@views/exam/index'),
  },
  {
    path: '/mockexams',
    name: 'mockexams',
    meta: {
      title: '模拟考试',
    },
    component: () => import('@views/exam/mockExams'),
  },
  {
    path: '/mockexams/startmock',
    name: 'mockexamsStart',
    meta: {
      title: '开始模拟考试',
    },
    component: () => import('@views/exam/mockExams/startMockExam'),
  },
  {
    path: 'detail',
    component: () => import('@views/exam/detail'),
    meta: {
      title: '我的考试',
      to: '/exam',
    },
    children: [
      {
        path: 'bandTestDetail',
        name: 'exam/bandTestDetail',
        meta: {},
        component: () => import('@views/exam/detail/bandTest'),
      },
      {
        path: 'examPay',
        name: 'exam/examPay',
        meta: {},
        component: () => import('@views/exam/examPay'),
      },
      {
        path: 'customTestDetail',
        name: 'exam/customTestDetail',
        meta: {},
        component: () => import('@views/exam/detail/customTest'),
      },
      {
        path: 'selfTestDetail',
        name: 'exam/selfTestDetail',
        meta: {},
        component: () => import('@views/exam/detail/selfTest'),
      },
      {
        path: 'stemaTestDetail',
        name: 'exam/stemaTestDetail',
        meta: {},
        component: () => import('@views/exam/detail/stemaTest'),
      },
      {
        path: 'bandtestBlockDetail',
        name: 'exam/bandtestBlockDetail',
        meta: {},
        component: () => import('@views/exam/detail/bandTestBlock'),
      },
    ],
  },
];
